@font-face {
    font-family: HelvaticaBold;
    src: url('./assets/fonts/helvetica-neue/HelveticaNeueBold.otf');
    font-weight: bold;
}
@font-face {
    font-family: HelvaticaBoldItalic;
    src: url('./assets/fonts/helvetica-neue/HelveticaNeueBoldItalic.otf');
    font-weight: bold;
}
@font-face {
    font-family: HelvaticaMedium;
    src: url('./assets/fonts/helvetica-neue/HelveticaNeueMedium.otf');
    font-weight: 500;
}
@font-face {
    font-family: HelvaticaRegular;
    src: url('./assets/fonts/helvetica-neue/HelveticaNeueLight.otf');
    font-weight: 400;
}
@font-face {
    font-family: HelvaticaThin;
    src: url('./assets/fonts/helvetica-neue/HelveticaNeueThin.otf');
    font-weight: 300;
}

.font-helvatica-bold {
    font-family: HelvaticaBold, sans-serif;
}
.font-helvatica-medium {
    font-family: HelvaticaMedium, sans-serif;
}
.font-helvatica-regular {
    font-family: HelvaticaRegular, sans-serif;
}
.font-helvatica-thin {
    font-family: HelvaticaThin, sans-serif;
}

h1 {
    line-height: 112px;
    font-family: HelvaticaBold, sans-serif;
    margin-bottom: 0px;
}

h2 {
    font-size: 32px;
    line-height: 38px;
    font-family: HelvaticaBold, sans-serif;
    margin-bottom: 0px;
}
h3 {
    font-size: 24px;
    line-height: 30px;
    font-family: HelvaticaBold, sans-serif;
    margin-bottom: 0px;
}
h4 {
    font-size: 20px;
    line-height: 24px;
    font-family: HelvaticaMedium, sans-serif;
    margin-bottom: 0px;
}
p {
    font-size: 16px;
    font-family: HelvaticaRegular, sans-serif;
    margin-bottom: 0px;
}

@media only screen
and (max-width: 580px) {
    h1 {
        line-height: 42px;
    }
}