.about-banner .swiper {
    width: 100%;
    height: 450px;
}
.about-banner .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.swiper-wrapper {
    transition-timing-function: linear;
 }

.portfolio-banner .slick-slider {
    margin:0 -15px;
}
.portfolio-banner .slick-slide {
    padding:10px;
    text-align:center;
    margin-right:15px;
    margin-left:15px;
}
.slick-slide img {
    width: -webkit-fill-available;
}