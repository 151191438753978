@media only screen
and (min-width: 1024px)
and (max-width: 1100px)
and (-webkit-min-device-pixel-ratio: 2) {
    .banner-area {
        width: 50% !important;
    }
}


.home-banner .swiper {
    width: 100%;
    height: 600px;
}
.home-banner .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}
.slide-media {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    width: 100%;
    object-fit: cover;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
}
.slide-media::after {
    content: ''; 
    position: absolute; 
    top: 0; 
    left: 0; 
    width: 100%; 
    height: 100%; 
    background-color: rgba(0, 0, 0, 0.5); 
    z-index: 0; 
}
.slide-media .gr-container {
    z-index: 99;
}
.slide-media p {
    font-family: HelvaticaMedium, sans-serif;
    font-size: 18px;
    margin-left: 5px;
}
@media only screen and (max-width: 580px) {
    .home-banner .swiper {
        width: 100%;
        height: 450px;
    }
}



#banner-hdg {
    animation: spin 10s linear infinite 1s;
}

.animated-word {
    animation: WordSlideIn 0.8s ease-out forwards;
  }
  
  @keyframes WordSlideIn {
    0% {
      transform: translateY(65%) rotateX(-95deg);
      opacity: 0;
    }
    100% {
      transform: translateY(0) rotateX(0);
      opacity: 1;
    }
  }




/* #banner-hdg span {
   animation: WordSlideOut .8s cubic-bezier(.86,0,.07,1) both;
}
@keyframes WordSlideOut {
    0% {
        transform: translateY(0) rotateX(0);
        opacity: 1;
    }
    100% {
        transform: translateY(-65%) rotateX(95deg);
        opacity: 0;
    }
}
@keyframes WordSlideIn {
    0% {
        transform: translateY(65%) rotateX(-95deg);
        opacity: 0;
    }
    100% {
        transform: translateY(0) rotateX(0);
        opacity: 1;
    }
} */


@keyframes spin {
    0% {
        content: 'Window treatments';
    }
    50% {
        content: 'House Renovation';
    }
    100% {
        content: 'House Construction';
    }
}

@media only screen
and (max-width: 580px) {
    .home-banner .swiper {
        height: 300px !important;
    }
}