@tailwind base;
@tailwind components;
@tailwind utilities;

*{
    scroll-behavior: smooth;
    overflow-x: hidden;
}

.gr-container {
    width: 100%;
    max-width: 1170px;
    margin: 0px auto;
    box-sizing: border-box;
}
.gr-btn {
    font-size: 18px;
    line-height: 24px;
    font-family: HelvaticaMedium, sans-serif;
    transition: all 1s;
}
.gr-btn:hover {
    color: #fd6050;
}
.ant-collapse-header {
    font-size: 16px !important;
    font-weight: 500 !important;
}