.outline-heading{
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke: 1px #b9b9ba;
    text-transform: capitalize;
    word-break: break-word;
    font-family: sans-serif;
    font-weight: 700;
    z-index: 1;
}
.find-btn {
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #FFFFFF;
    padding: 1.2em 5.2em 1.2em 5.2em;
    font-family: HelvaticaMedium, sans-serif;
    /* margin-top: 20px; */
    border: 1px solid #FFFFFF2E;
    transition: all 0.8s;
}
.find-btn:hover {
  color: #000;
  background-color: #fff;
  border: 1px solid #FFFFFF2E;
}
.ftr-hdg {
  font-size: 36px;
  line-height: 42px;
  font-family: HelvaticaThin, sans-serif;
}
.ftr-hdg span {
  font-size: 36px;
  line-height: 42px;
  font-family: HelvaticaBoldItalic, sans-serif;
}
/* .txt-rotate-keywords {
    display: inline-block;
    position: relative;
    white-space: nowrap;
    transition: width .8s cubic-bezier(.86,0,.07,1);
    will-change: width;
} */
